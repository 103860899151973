import React from "react"

import { graphql } from "gatsby"
import { SearchState } from "react-instantsearch-core"
import { IntlShape, useIntl } from "react-intl"

import {
  formatBannerToDisplay,
  PrismicBanners,
} from "../../components/Banner/utils"
import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import SEO from "~/components/Seo/Seo"
import { PageContextCategory } from "~/templates/Category/Category"
import FilterPage from "~/templates/Category/FilterPage"
import {
  createSearchURL,
  getQueriedSearchURL,
  searchStateToSearchUrl,
  searchUrlToSearchState,
} from "~/templates/Category/utils/searchUrlUtils"
import { capitalizeFirstLetter } from "~/utils/string"

interface PropTypes extends TemplateDefaultPropTypes {
  pageContext: TemplateDefaultPropTypes["pageContext"] & PageContextCategory
  data: { prismicBanner: PrismicBanners | null }
}

interface SearchTypes {
  title: string
  intl: IntlShape
}

function ResultSEO({ title, intl }: SearchTypes) {
  const lang = intl.locale

  const queriedSearchURL = getQueriedSearchURL(lang, intl)
  const queriedCanonical = queriedSearchURL && encodeURI(queriedSearchURL)

  return (
    <SEO
      title={title}
      link={[{ rel: "canonical", href: queriedCanonical || "" }]}
    />
  )
}

function SearchPage({
  location,
  pageContext,
  data,
  path,
  navigate,
}: PropTypes) {
  const urlParams = new URLSearchParams(location.search)
  const keywordParam = urlParams.get("q")
  const intl = useIntl()

  const refreshTitle = (
    searchState: SearchState
  ): {
    pageTitle: string
  } => {
    const query = searchState.query
    const pageTitle = query
      ? `${capitalizeFirstLetter(query.toLowerCase())} - ${intl.formatMessage(
          {
            id: "category/text:recipes-chefclub",
          },
          {
            count: "",
          }
        )}`
      : intl
          .formatMessage({ id: "category/text:all-recipes-chefclub" })
          .replace("%s", "")

    return {
      pageTitle,
    }
  }

  const bannerToDisplay = data.prismicBanner
    ? formatBannerToDisplay(data.prismicBanner)
    : null
  return (
    <>
      <ResultSEO
        title={keywordParam ? `Search - ${keywordParam}` : "Search"}
        intl={intl}
      />

      <FilterPage
        pageContext={pageContext}
        createURL={createSearchURL}
        urlToSearchState={searchUrlToSearchState}
        searchStateToUrl={searchStateToSearchUrl}
        path={path}
        navigate={navigate}
        location={location}
        refreshTitle={refreshTitle}
        baseSlug={intl.formatMessage({ id: "path:search" })}
        bannerToDisplay={bannerToDisplay}
      />
    </>
  )
}
export default withTemplate(SearchPage)

//You can find the fragment definition in src/components/Banner/Banner.tsx
export const query = graphql`
  query SearchBanners($prismicLanguage: String) {
    prismicBanner(
      data: { banner_type: { eq: "filtered_recipes_page" } }
      lang: { eq: $prismicLanguage }
    ) {
      ...Banner
    }
  }
`
